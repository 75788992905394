
import axios from "../../config/axios"
import { base_url_machine_learning, base_url_auto_dubbing } from '../../config/base_url'
import ApiRoute from '../api.route';

export async function getScriptAutodub(formData) {
    return axios.post(base_url_auto_dubbing + ApiRoute.autodub + '/get-script', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function generateScriptAutodub(formData) {
    return axios.
        post(base_url_auto_dubbing + ApiRoute.autodub + '/generate-script', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function translateText(formData) {
    return axios.post(base_url_machine_learning + 'master_languages/translate-text', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getAutodubList() {
    return axios.get(ApiRoute.autodubProjects + 'list')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getAutodubDetail(id) {
    return axios.get(ApiRoute.autodubProjects + 'detail/' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getAutodubPreview(id) {
    return axios.get(ApiRoute.autodubProjects + 'preview/' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function postAutodub(formData) {
    return axios.post(ApiRoute.autodubProjects + 'create', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function postFeedbackAutodub(formData, id) {
    return axios.patch(ApiRoute.autodubProjects + 'feedback/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function renameAutoDub(formData, id) {
    return axios.patch(ApiRoute.autodubProjects + 'rename/' + id, formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function deleteAutoDub(id) {
    return axios.delete(ApiRoute.autodubProjects + 'delete/' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function getVideoWatermark(id) {
    return axios.get(base_url_auto_dubbing + ApiRoute.autodub + '/watermark?job_id=' + id)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function uploadFileByChunks(formData) {
    return axios.post(base_url_auto_dubbing + ApiRoute.autodub + '/upload-chunk', formData)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error.response }
            return data;
        })
}

export async function autodubbCheckAccess() {
    return axios.get(ApiRoute.autodubProjects + 'check-access')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getQueueAutodub() {
    return axios.get(base_url_auto_dubbing + ApiRoute.autodub + '/total_queue')
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}

export async function getStreamObj(scriptPath) {
    return axios.get(base_url_auto_dubbing + ApiRoute.autodub + '/stream_obj?path=' + scriptPath)
        .then(res => {
            const data = res.data
            return data;
        })
        .catch((error) => {
            const data = { status: false, response: error }
            return data;
        })
}